module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"auTech Hörsysteme","short_name":"auTech","description":"auTech Hörsysteme GmbH ist ein modernes Hörakustik-Unternehmen, das seine Dienstleistungen an verschiedenen Standorten anbietet.","start_url":"/","background_color":"#fe8903","theme_color":"#fe8903","display":"minimal-ui","cache_busting_mode":"none","theme_color_in_head":false,"icons":[{"src":"/icons-maskable/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-144x144.png","sizes":"128x128","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"include_favicon":false,"legacy":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["./icons-maskable/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.autech-hoersysteme.de/graphql","html":{"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
